import React, { Component } from 'react';
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import EventPage from './pages/EventPage';
import NotFound from './pages/404';

import data from './data';
import './App.css';

const routesArray = [
  {
    path: "/2-14"
  },
  {
    path: "/2-15"
  },
  {
    path: "/2-16"
  },
  {
    path: "/2-17"
  },
  {
    path: "/2-18"
  },
  {
    path: "/2-19"
  },
  {
    path: "/2-20"
  },
  {
    path: "/2-21"
  },
  {
    path: "/2-22"
  }
];

function Routes(route) {
  let dateData;
  let isFestivalDay = false;

  data.eventsArray.filter(item => {
    let shortDate = `/${item.shortDate}`;

    if (shortDate === route.path) {
      dateData = item;
    }

    return dateData
  })

  if (route.path === "/2-22") {
    isFestivalDay = true;
  }

  return (
    <Route path={route.path}>
      <EventPage dateDetails={dateData} festivalDay={isFestivalDay} />
    </Route>
  );
}

const HeaderWithRouteProps = withRouter(props => <Header {...props}/>);


class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Router>
        <div className="ccbw-page">
          <HeaderWithRouteProps data={data} location={this.props}></HeaderWithRouteProps>
          <main className="ccbw-page__content">
            <Switch>
              <Route exact path="/">
                <Home events={data} />
              </Route>
              {
                routesArray.map((r, i) => (
                  <Routes key={i} {...r} />
                ))
              }
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </main>
          <Footer></Footer>
        </div>
      </Router>
    );
  }
}

export default App;
