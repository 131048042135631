import React from 'react';
import EventList from '../components/EventList';

import logo from '../assets/logos/colacraft20.png';
import Video from '../assets/video/video-min.mp4';
import '../styles/05-components/home-page.css';
import Poster from '../assets/images/video-still-min.png'

export default props => 
    <div className="ccbw-home-page">
        <div className="ccbw-home-page__banner">
            <video autoPlay muted loop playsInline poster={Poster} id="myVideo" className="ccbw-home-page__video">
                <source src={Video} type="video/mp4" />
            </video>
            <img
                className="ccbw-home-page__image"
                src={logo} 
                alt="Columbia Craft Beer Week Logo"
            />
        </div>
        <div className="contain">
            <ul className="ccbw-home-page__events">
                {
                    props.events.eventsArray.map((date, index) => 
                        <li key={index} className="ccbw-home-page__item">
                            <h1 className="ccbw-home-page__subtitle">
                                <span className="ccbw-home-page__day">{date.day}</span>
                                <span className="ccbw-home-page__date">{date.date}</span>
                                <span className="ccbw-home-page__divider"></span>
                            </h1>
                            <EventList 
                                events={date.events} 
                                date={date.shortDate} 
                                listType="simple"
                            ></EventList>
                        </li>
                    )
                }
            </ul>
        </div>
    </div>
    
