import React from 'react';
import EventList from '../components/EventList';

import '../styles/05-components/eventPage.css';

export default props => {

    if (!props.festivalDay) {
        return (
            <div>
                <header className="ccbw-event-page__header">
                    <div className="ccbw-event-page contain-extreme">
                        <h1 className="ccbw-event-page__title">
                            <span className="ccbw-event-page__day">{props.dateDetails.day}</span>
                            <span className="ccbw-event-page__date">{props.dateDetails.date}</span>
                            <span className="ccbw-event-page__divider"></span>
                        </h1>
                    </div>
                </header>
                <div className="ccbw-event-page__banner"></div>
                <div className="ccbw-event-page contain-extreme">
                    {
                        props.dateDetails.events.length >= 1 
                            ? <EventList events={props.dateDetails.events} listType="detailed"></EventList>
                            : <p>No Events Scheduled</p>
                    }
                </div>
            </div>
        )
    }

    if (props.festivalDay) {
        return (
            <div>
                <header className="ccbw-event-page__header">
                    <div className="ccbw-event-page contain-extreme">
                        <h1 className="ccbw-event-page__title">
                            <span className="ccbw-event-page__day">Saturday</span>
                            <span className="ccbw-event-page__date">February 22</span>
                            <span className="ccbw-event-page__divider"></span>
                        </h1>
                    </div>
                </header>
                <div className="ccbw-event-page__banner"></div>
                <div className="ccbw-event-page contain-extreme">
                    <ul className="ccbw-event-list ccbw-festival">
                        <li className="ccbw-event-list__item">
                            <article className="ccbw-event" id="world-beer-festival">
                                <div className="ccbw-event__image-container">
                                    <img className="ccbw-event__image" src={`./assets/images/WBF_logo_COLUMBIA.png`} alt="world beer festival logo"/>
                                    <img className="ccbw-event__image" src={`./assets/images/kw-logo.png`} alt="world beer festival logo"/>
                                </div>
                                <div className="ccbw-event__container">
                                    <h2 className="ccbw-event__title">World Beer Festival Columbia presented by KW Beverage</h2>
                                    <div className="ccbw-event__header">
                                        <div className="ccbw-event__times">
                                            <p className="ccbw-event__time">
                                                {/* <span className="ccbw-event__session-label">First Session</span> */}
                                                <span className="ccbw-event__start-time">First Session</span>
                                                <span className="ccbw-event__end-time">12:00pm - 4:00pm</span>
                                            </p>
                                            <p className="ccbw-event__time">
                                                {/* <span className="ccbw-event__session-label">Second Session</span> */}
                                                <span className="ccbw-event__start-time">Second Session</span>
                                                <span className="ccbw-event__end-time">6:00pm - 10:00pm</span>
                                            </p>
                                        </div>
                                        <div className="ccbw-event__location">
                                            <p className="ccbw-event__host">South Carolina State Fairgrounds - Gate 6</p>
                                            <address className="ccbw-event__address">
                                                1200 Rosewood Drive<br/>Columbia, SC 29201
                                            </address>
                                        </div>
                                        </div>
                                    <div className="ccbw-event__details">
                                        <p className="ccbw-event__description">No excuse NOT to come! Join us on February 22, 2020 to celebrate 12 years of great beer. World Beer Festival Columbia presented by KW Beverage will feature a sampling of local, national and international breweries. And if the beer isn’t good enough, our 2020 event will feature great food, music and making memories with friends!</p>
                                        <a href="https://tickets.worldbeerfestival.com/e/world-beer-festival-columbia-2020-presented-by-kw/tickets" target="_blank" rel="noopener noreferrer" className="ccbw-event__button">Buy Tickets</a>
                                    </div>
                                </div>
                            </article>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}