import React from "react";
import LogoLink from './LogoLink';
import MainNav from './MainNav';
import logo from '../assets/logos/colacraft20.png';
import '../styles/05-components/header.css'

export default props => 
    <header className="ccbw-header">
        {
            props.location.pathname !== "/" && 
            <div className="ccbw-header__logo-container">
                <div className="ccbw-header__container contain-extreme">
                    <h1 className="sr-only">Columbia Craft Beer Week</h1>
                    <LogoLink
                        url="\"
                        imageUrl={logo} 
                        imageAltText="Columbia Craft Beer Week Logo"
                    ></LogoLink>
                </div>
            </div>
        }
        <div className="ccbw-header__nav-container">
            <div className="ccbw-header__container contain-extreme">
                <MainNav data={props.data}></MainNav>
            </div>
        </div>
    </header>