import React from "react"
import { Link } from "react-router-dom";

import '../styles/05-components/logoLink.css'

export default props => {
  return (
    <Link className="ccbw-logo-link" to="/">
      <img 
        className="ccbw-logo-link__image"
        src={props.imageUrl}
        alt={props.imageAltText} />
    </Link>
  )
}