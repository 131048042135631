import React from 'react';

import '../styles/05-components/eventCard.css';

export default props => {

    if (props.type === "simple") {
        return <a href={`/${props.date}#${props.event.linkTitle}`}>{props.event.title}</a>
    }

    return (
        <article className="ccbw-event" id={props.event.linkTitle}>
            {
                props.event.attachments ?
                <div className="ccbw-event__image-container">
                    {
                        props.event.attachments.map((item, index) => {
                            return (
                                <img className="ccbw-event__image" src={`./assets/images/${item}`} key={index} alt="ccbw-event logo"/>
                            )
                        })
                    }
                </div>
                : null
            } 
            <div className="ccbw-event__container">
                <div className="ccbw-event__header">
                    <p className="ccbw-event__time">
                        <span className="ccbw-event__start-time">{props.event.startTime}</span>
                        {
                            props.event.endTime ?
                                <span className="ccbw-event__end-time">{props.event.endTime}</span>
                            : null
                        }
                    </p>
                    <div className="ccbw-event__location">
                        <p className="ccbw-event__host">{props.event.host}</p>
                        <address className="ccbw-event__address">
                            {props.event.addressLineOne}<br/>{props.event.addressLineTwo}
                        </address>
                    </div>
                </div>
                <h2 className="ccbw-event__title">{props.event.title}</h2>
                <div className="ccbw-event__details">
                    <p className="ccbw-event__breweries">Featured Breweries: {props.event.brewery}</p>
                    <p className="ccbw-event__description">{props.event.description}</p>
                    {
                        props.event.link && 
                        <a className="ccbw-event__link" href={props.event.link} target="_blank" rel="noopener noreferrer">More Info</a>
                    }
                </div>
            </div>
        </article>
    )
}