import React from 'react';

import '../styles/05-components/footer.css'

export default props => 
    <footer className="ccbw-footer">
        <div className="ccbw-footer__container contain">
            <section className="ccbw-footer__info">
                <h2 className="sr-only">Columbia Craft Beer Week</h2>
                <div className="ccbw-footer__left">
                    <p className="ccbw-footer__copyright">© 2020 by KW Beverage</p>
                    <address className="ccbw-footer__address">
                        825 Bluff Road<br/>
                        Columbia, SC 29201
                    </address> 
                    <p className="ccbw-footer__phone">Tel: (803) 799-5490</p>
                </div>
                <div className="ccbw-footer__right">
                    <p><a href="http://kwbeverage.com">kwbeverage.com</a></p>
                </div>
            </section>
        </div>
    </footer>