import React from 'react';
import { Link } from 'react-router-dom';

export default props =>
    <div>
        {/* <header className="ccbw-event-page__header">
            <div className="ccbw-event-page contain-extreme">
                <h1 className="ccbw-event-page__title">
                    Page Not Found
                    <span className="ccbw-event-page__divider"></span>
                </h1>
            </div>
        </header> */}
        <div className="ccbw-event-page__banner"></div>
        <div className="ccbw-event-page contain-extreme">
            <div className="ccbw-404">
                <h1>Oops!</h1>
                <h2>Page Not Found</h2>
                <Link className="ccbw-404__link" to="/">Return to the Home Page</Link>
                <p>Error Code: 404</p>
            </div>
        </div>
    </div>