import React from 'react';
import EventCard from './EventCard';

import '../styles/05-components/eventList.css';

export default props => (
    <ul className="ccbw-event-list">
        {
            props.events.map((event, index) => {
                return (
                    <li className="ccbw-event-list__item" key={index}>
                        <EventCard event={event} type={props.listType} date={props.date}/>
                    </li>
                )
            })
        }
    </ul>
)