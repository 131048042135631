import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/05-components/mainNav.css';

export default props =>
    <nav className="ccbw-nav">
        <ul className="ccbw-nav__list">
            {
                props.data.eventsArray.map((item, key) =>
                    <li className="ccbw-nav__item" key={key}>
                        <NavLink className="ccbw-nav__link" to={item.shortDate} activeClassName="ccbw-nav__link--active">
                            <span className="ccbw-nav__day">{item.menuLabels.day}</span>
                            <span className="ccbw-nav__date">{item.menuLabels.date}</span>
                        </NavLink>
                    </li>
                )
            }
        </ul>
    </nav>