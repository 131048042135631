export const eventsArray = [
    {
        shortDate: "2-14",
        date: "February 14",
        day: "Friday",
        menuLabels: {
            day: "Fri",
            date: "14"
        },
        events: [
            {
                title: "Hoppy Valentine's Day",
                startTime: "5:00pm",
                endTime: "8:00pm",
                host: "Greens",
                brewery: "Wicked Weed",
                addressLineOne: "4012 Fernandina Road",
                addressLineTwo: "Columbia, SC 29212",
                linkTitle: "hoppy-valentines",
                attachments: ["wicked-weed.png", "greens.png"],
                description: "Celebrate Valentine's day with all of the hops!"
            }
        ]
    },
    {
        shortDate: "2-15",
        date: "February 15",
        day: "Saturday",
        menuLabels: {
            day: "Sat",
            date: "15"
        },
        events: [
            {        
                title: "Biergarten Warming Party",
                startTime: "12:00pm",
                endTime: "8:00pm",
                host: "WECO Bottle + Biergarten",
                brewery: "Multiple",
                addressLineOne: "626 Meeting Street",
                addressLineTwo: "West Columbia, SC 29169",
                linkTitle: "biergarten-warming-party",
                attachments: ["weco.svg"],
                description: "Kickoff Columbia Craft Beer Week by helping us break in and warm up West Columbia's new biergarten."
            },
            {        
                title: "Narragansett Comedy Night",
                startTime: "7:00pm",
                endTime: "",
                host: "The Comedy Closet",
                brewery: "Narragansett",
                addressLineOne: "735 Meeting Street",
                addressLineTwo: "West Columbia, SC 29169",
                linkTitle: "narragansett-comedy-night",
                attachments: ["narragansett.png"],
                description: "Wrestling stories about the LEGENDs who made your childhood ROCK!  Featuring Hacksaw Jim Duggan. Meet/ Greet before and after show including fan experience. Narragansett 12oz cans $ 2.50 each, The Drunken Clam special $5 (beer w/ 1/2 shot house liquor). Doors open at 7:00pm."
            }
        ]
    },
    {
        shortDate: "2-16",
        date: "February 16",
        day: "Sunday",
        menuLabels: {
            day: "Sun",
            date: "16"
        },
        events: [
            {        
                title: "Sn-Axe-ing in Columbia",
                startTime: "2:00pm",
                endTime: "5:00pm",
                host: "Craft Axe Columbia",
                brewery: "Palmetto, Catawba, Twisp",
                addressLineOne: "700 Gervais Street, Suite B2",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "sn-axe-ing-in-columbia",
                attachments: ["catawba.png", "pbc.png"],
                description: "Snacks and Craft Axe with Palmetto Catawba!"
            }
        ]
    },
    {
        shortDate: "2-17",
        date: "February 17",
        day: "Monday",
        menuLabels: {
            day: "Mon",
            date: "17"
        },
        events: [
            {
                title: "Hop Acid Release Party",
                startTime: "4:00pm",
                endTime: "8:00pm",
                host: "Craft and Draft Irmo",
                brewery: "Columbia Craft",
                addressLineOne: "7583 St Andrews Road",
                addressLineTwo: "Irmo, SC 29063",
                linkTitle: "hop-acid",
                attachments: ["cola-craft-logo.jpg", "cd.gif"],
                description: "Columbia Craft Hop Acid release party with some other specialty beers too!"
            },
            {
                title: "Hi-Wire Tasting",
                startTime: "4:30pm",
                endTime: "7:30pm",
                host: "Bottles Beverage Superstore",
                brewery: "Hi-Wire",
                addressLineOne: "4410 Fort Jackson Boulevard",
                addressLineTwo: "Columbia, SC 29209",
                linkTitle: "hi-wire-tasting",
                attachments: ["Hi-Wire-Brewing.png", "Bottles.jpg"],
                description: "Hi-Wire teamed up with some of the legends of the industry to create 3 amazing limited release collaborations!"
            },
            {
                title: "President's Funday",
                startTime: "5:00pm",
                endTime: "9:00pm",
                host: "The Whig",
                brewery: "Wicked Weed",
                addressLineOne: "1200 Main Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "presidents-funday",
                attachments: ["wicked-weed.png"],
                description: "George Washington didn't have a middle name…but that's not the point, vote for your favorite beer on tap, a very nice priced lineup by Wicked Weed will be oh so gentle on your dead presidents!",
                link: "https://www.facebook.com/events/2386464668331386/"
            },
            {
                title: "Pub Run",
                startTime: "6:30pm",
                endTime: "",
                host: "British Bulldog",
                brewery: "Goose Island",
                addressLineOne: "1220 Bower Parkway E-10",
                addressLineTwo: "Columbia, SC 29212",
                linkTitle: "pub-run",
                attachments: ["goose-island-logo.png"],
                description: "6:30 Pub Run, 7:00pm Music Bingo"
            },
        ]
    },
    {
        shortDate: "2-18",
        date: "February 18",
        day: "Tuesday",
        menuLabels: {
            day: "Tue",
            date: "18"
        },
        events: [
            {
                title: "Steel Hands Happy Hour",
                startTime: "4:00pm",
                endTime: "8:00pm",
                host: "Craft and Draft Irmo",
                brewery: "Steel Hands",
                addressLineOne: "7583 St Andrews Road",
                addressLineTwo: "Irmo, SC 29063",
                linkTitle: "steel-hands-happy-hour",
                attachments: ["cd.gif"],
                description: ""
            },
            {
                title: "Victory Happy Hour",
                startTime: "4:00pm",
                endTime: "8:00pm",
                host: "Jake's",
                brewery: "Victory",
                addressLineOne: "2112 Devine Street",
                addressLineTwo: "Columbia, SC 29205",
                linkTitle: "victory-happy-hour",
                attachments: ["victory.png"],
                description: "Happy Hour with the Monkey's!"
            },
            {
                title: "Tap Takeover",
                startTime: "4:00pm",
                endTime: "7:00pm",
                host: "Lowes Foods of Forest Acres",
                brewery: "Wicked Weed",
                addressLineOne: "4711 Forest Drive",
                addressLineTwo: "Columbia, SC 29206",
                linkTitle: "tap-takeover",
                attachments: ["wicked-weed.png"],
                description: "Come to Lowes on Forest Drive to enjoy a plethora of Wicked Weed Brewing styles, and maybe buy a few groceries too!"
            },
            {
                title: "Hi-Wire Brewing Taco Tuesday",
                startTime: "4:00pm",
                endTime: "12:00am",
                host: "The Whig",
                brewery: "Hi-Wire",
                addressLineOne: "1200 Main Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "hi-wire-brewing-taco-tuesday",
                attachments: ["Hi-Wire-Brewing.png"],
                description: "What's better than tacos and beer?  Special tacos and special beers for Columbia Craft Beer Week with Hi-Wire Brewing.",
                link: "https://www.facebook.com/events/839215003194164/"
            },
            {
                title: "Sugar Creek Tasting",
                startTime: "4:30pm",
                endTime: "7:30pm",
                host: "Bottles Beverage Superstore",
                brewery: "Sugar Creek",
                addressLineOne: "4410 Fort Jackson Boulevard",
                addressLineTwo: "Columbia, SC 29209",
                linkTitle: "sugar-creek-tasting",
                attachments: ["sc.jpg"],
                description: "First Columbia release of Charlotte's Sugar Creek Brewing Company's Hazy Creek IPA!"
            },
            {
                title: "Fat Cat's Biscuits at Columbia Craft",
                startTime: "5:00pm",
                endTime: "9:00pm",
                host: "Columbia Craft",
                brewery: "Columbia Craft",
                addressLineOne: "520 Greene Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "fat-cats-biscuits",
                attachments: ["cola-craft-logo.jpg"],
                description: "Create your own biscuit flight to pair with our beers... use our recommendations or create your own pairings!",
                link: "https://www.facebook.com/events/131386131445395/"
            },
            {
                title: "Buy the Pint, Keep the Glass with Brewery 85",
                startTime: "5:00pm",
                endTime: "8:00pm",
                host: "Pizza Joint",
                brewery: "Brewery 85",
                addressLineOne: "3246 Forest Drive",
                addressLineTwo: "Columbia, SC 29204",
                linkTitle: "buy-the-pint-keep-the-glass",
                attachments: ["85.jpg"],
                description: "Buy the pint, keep the glass of some Greenville brewed faves."
            },
        ]
    },
    {
        shortDate: "2-19",
        date: "February 19",
        day: "Wednesday",
        menuLabels: {
            day: "Wed",
            date: "19"
        },
        events: [
            {
                title: "Brewery 85 Spotlight",
                startTime: "4:30pm",
                endTime: "7:30pm",
                host: "Bottles Beverage Superstore",
                brewery: "Brewery 85",
                addressLineOne: "4410 Fort Jackson Boulevard",
                addressLineTwo: "Columbia, SC 29209",
                linkTitle: "brewery-85-spotlight",
                attachments: ["85.jpg"],
                description: "Sample two limited release beers from Greenville SC's own Brewery 85."
            },
            {
                title: "Cookie Pairing",
                startTime: "6:00pm",
                endTime: "9:00pm",
                host: "Casual Pint Lexington",
                brewery: "Wicked Weed",
                addressLineOne: "217 Saluda Springs Road",
                addressLineTwo: "Lexington, SC 29072",
                linkTitle: "cookie-pairing",
                attachments: ["wicked-weed.png"],
                description: "Join us on Wednesday, the 19th, as we do a Wicked Weed pairing with Girl Scout Cookies! Tickets will be available for purchase for $15 through EventBrite. Pairing details to come.",
                link: "https://www.facebook.com/events/2270110446623268/"
            },
            {
                title: "Southern Tier Tap Takeover",
                startTime: "6:00pm",
                endTime: "9:00pm",
                host: "Random Tap",
                brewery: "Southern Tier",
                addressLineOne: "117 Spears Creek Church Road",
                addressLineTwo: "Elgin, SC 29045",
                linkTitle: "southern-tier-tap-takeover",
                attachments: ["st.png"],
                description: ""
            }
        ]
    },
    {
        shortDate: "2-20",
        date: "February 20",
        day: "Thursday",
        menuLabels: {
            day: "Thu",
            date: "20"
        },
        events: [
            {
                title: "Cowboy at the Cowboy",
                startTime: "TBD",
                endTime: "",
                host: "Keg Cowboy",
                brewery: "Palmetto, Catawba",
                addressLineOne: "108 East Main Street",
                addressLineTwo: "Lexington, SC 29072",
                linkTitle: "cowboy-at-the-cowboy",
                attachments: ["pbc.png"],
                description: "Drink some local favorites with the Palmetto/ Catawba Cowboy!"
            },
            {
                title: "Brewery 85 Buy the Pint, Keep the Glass",
                startTime: "5:00pm",
                endTime: "8:00pm",
                host: "Liberty Taproom",
                brewery: "Brewery 85",
                addressLineOne: "828 Gervais Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "buy-the-pint-keep-the-glass",
                attachments: ["85.jpg"],
                description: "Buy the pint, keep the glass of some Greenville brewed faves."
            },
            {
                title: "Arts and Drafts",
                startTime: "TBD",
                endTime: "",
                host: "Mellow Mushroom Vista",
                brewery: "Wicked Weed",
                addressLineOne: "1009 Gervais Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "arts-and-drafts",
                attachments: ["wicked-weed.png"],
                description: "Coloring contests with Wicked Weed adult coloring books and other artsy things."
            },
            {
                title: "Battle of the Sours",
                startTime: "4:30pm",
                endTime: "7:30pm",
                host: "Bottles Beverage Superstore",
                brewery: "Columbia Craft, Wicked Weed",
                addressLineOne: "4410 Fort Jackson Boulevard",
                addressLineTwo: "Columbia, SC 29209",
                linkTitle: "battle-of-the-sours",
                attachments: ["cola-craft-logo.jpg", "wicked-weed.png"],
                description: "Bottles will be featuring multiple specialty offerings to sample from Columbia Craft and Wicked Weed! You can even go home with a growler or two of your favorite!"
            },
            {
                title: "Bikes N Brews",
                startTime: "5:45pm",
                endTime: "8:45pm",
                host: "Henry's, Steel Hands, and WECO Bottle + Biergarten",
                brewery: "Steel Hands",
                addressLineOne: "123 Street Address",
                addressLineTwo: "Columbia, SC",
                linkTitle: "bikes-n-brews",
                description: "We're celebrating Columbia Craft Beer Week with our 2nd Annual Bike-n-Brews cycling event and partnering with ColaTown Bike Collective! What better way to enjoy the outdoors, local craft brews and be a part of the craft beer community!",
                attachments: ["bike.png"],
                link: "https://www.facebook.com/events/555247271735091/?active_tab=about"
            },
            {
                title: "Sold Out: Partner Beer Yoga",
                startTime: "6:30pm",
                endTime: "7:30pm",
                host: "Columbia Craft",
                brewery: "Columbia Craft",
                addressLineOne: "520 Greene Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "partner-beer-yoga",
                attachments: ["cola-craft-logo.jpg"],
                description: "Calling all Valentines and/ or Galentines!  Grab a beer, grab your favorite partner and get your asana to your mat!  Partner yoga you ask?  While doing our traditional beer yoga, this class will incorporate some fun poses you and your fave will do together.  Partner Yoga allows you to challenge yourself physically and emotionally allowing you to place your body and trust in your partner's hands...literally!  Class will be a 'post' Valentine's Day theme.  All participants will receive 25% off their tab at Columbia Craft.  Tickets sold by Eventbrite $10.",
                link: "https://www.facebook.com/events/590224251830112/"
            },
            {
                title: "Chocolate Truffle Pairing",
                startTime: "8:00pm",
                endTime: "10:00pm",
                host: "The Grand",
                brewery: "Bold Rock",
                addressLineOne: "1621 Main Street",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "chocolate-truffle-pairing",
                attachments: ["br.png"],
                description: "Truffles and Cider for a late Valentine's pairing!"
            },
        ]
    },
    {
        shortDate: "2-21",
        date: "February 21",
        day: "Friday",
        menuLabels: {
            day: "Fri",
            date: "21"
        },
        events: [
            {
                title: "Winterfest",
                startTime: "TBD",
                endTime: "",
                host: "Craft and Draft Devine",
                brewery: "Bold Rock, Catawba, Palmetto, Wicked Weed",
                addressLineOne: "2706 Devine Street",
                addressLineTwo: "Columbia, SC 29205",
                linkTitle: "winterfest",
                attachments: ["br.png", "catawba.png", "pbc.png", "wicked-weed.png"],
                description: "Beer Week party with Bold Rock, Wicked Weed, Palmetto and Catawba proving that even competitors can be friends when beer is involved!"
            },
            {
                title: "Closing Time",
                startTime: "TBD",
                endTime: "",
                host: "Urban Cookhouse Devine",
                brewery: "Columbia Craft",
                addressLineOne: "3000 Devine Street",
                addressLineTwo: "Columbia, SC 29205",
                linkTitle: "closing-time",
                attachments: ["cola-craft-logo.jpg"],
                description: "Celebrate the second to last day of CCBW with Columbia Craft, Urban Cookhouse and local businesses on Devine Street."
            },
            {
                title: "Pours & S'Mores",
                startTime: "TBD",
                endTime: "",
                host: "World of Beer",
                brewery: "Devil's Backbone",
                addressLineOne: "902 Gervais Street Suite F",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "pours-smores",
                attachments: ["db.gif"],
                description: "Keep it slow by nature and kick back with some tasty Devils Backbone brews paired with make-your-own S'Mores!"
            },
        ]
    },
    {
        shortDate: "2-22",
        date: "February 22",
        day: "Saturday",
        menuLabels: {
            day: "Sat",
            date: "22"
        },
        events: [
            {
                title: "World Beer Festival Columbia 2020 presented by KW Beverage",
                startTime: "TBD",
                endTime: "",
                host: "South Carolina State Fairgrounds - Gate 6",
                brewery: "Multiple",
                addressLineOne: "1200 Rosewood Drive",
                addressLineTwo: "Columbia, SC 29201",
                linkTitle: "world-beer-festival",
                attachments: [],
                description: ""
            }
        ]
    }
];

export default { eventsArray }

// {
//     shortDate: "2-22",
//     date: "February 22",
//     day: "Saturday",
//     menuLabels: {
//         day: "Sat",
//         date: "22"
//     },
//     events: [
//         {
//             title: "World Beer Festival Columbia 2020 presented by KW Beverage",
//             startTime: "TBD",
//             endTime: "",
//             host: "South Carolina State Fairgrounds - Gate 6",
//             brewery: "Multiple",
//             addressLineOne: "1200 Rosewood Drive",
//             addressLineTwo: "Columbia, SC 29201",
//             linkTitle: "world-beer-festival",
//             attachments: [],
//             description: ""
//         }
//     ]
// }

//removed from the 18th events
// {
//     title: "Untitled",
//     startTime: "TBD",
//     endTime: "",
//     host: "Publico",
//     brewery: "Allagash",
//     addressLineOne: "2013 Greene Street",
//     addressLineTwo: "Columbia, SC 29205",
//     linkTitle: "untitled-3",
//     attachments: [],
//     description: ""
// },
